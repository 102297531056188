import './Header.scss';
import { Link } from 'react-router-dom';
import burgerButton from '../../assets/images/icons/burger-button.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Header = ({ mobileMenu, setMobileMenu, language, setLanguage }) => {
    const { t, i18n } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false); // Track modal state
    const [formData, setFormData] = useState({ fullName: '', phone: '', comment: '' }); // Form data
    const [submitted, setSubmitted] = useState(false); // Track form submission

    const languageChanger = (language) => {
        if (language === 'uz') {
            i18n.changeLanguage('uz');
            setLanguage('uz');
        } else if (language === 'ru') {
            i18n.changeLanguage('ru');
            setLanguage('ru');
        } else if (language === 'eng') {
            i18n.changeLanguage('eng');
            setLanguage('eng');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        setTimeout(() => {
            setIsModalOpen(false);
            setFormData({ fullName: '', phone: '', comment: '' }); // Reset form
            setSubmitted(false);
        }, 2000); // Show thank you message for 2 seconds
    };

    return (
        <header className="header">
            <div className="container">
                <div className="header-left">
                    <Link to="/" className="logo">
                        IDEAL WORLD TOUR
                    </Link>
                </div>
                <button
                    className="burger-button"
                    onClick={() => setMobileMenu(!mobileMenu)}
                >
                    <img src={burgerButton} alt="" />
                </button>
                <div className="header-center">
                    <ul className="header-center-linkers">
                        <li>
                            <Link to="/">{t('header.home')}</Link>
                        </li>
                        <li>
                            <Link to="/about">{t('header.about')}</Link>
                        </li>
                        <li>
                            <Link to="/blog">{t('header.blog')}</Link>
                        </li>
                    </ul>
                </div>
                <div className="header-right">
                    <div id="language-switcher" className="button-group">
                        <button onClick={() => languageChanger('eng')} id="btn-en">English</button>
                        <button onClick={() => languageChanger('uz')} id="btn-uz">O'zbek</button>
                        <button onClick={() => languageChanger('ru')} id="btn-ru">Русский</button>
                    </div>
                    <button className="book-button" onClick={() => setIsModalOpen(true)}>
                        {t('header.book')}
                    </button>
                </div>
            </div>

            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <button className="close-button" onClick={() => setIsModalOpen(false)}>
                            &times;
                        </button>
                        {!submitted ? (
                            <form onSubmit={handleSubmit}>
                                <h2>{t('header.bookNow')}</h2>
                                <label>
                                    {t('header.fullName')}
                                    <input
                                        type="text"
                                        name="fullName"
                                        value={formData.fullName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </label>
                                <label>
                                    {t('header.phone')}
                                    <input
                                        type="tel"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </label>
                                <label>
                                    {t('header.comment')}
                                    <textarea
                                        name="comment"
                                        value={formData.comment}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </label>
                                <button type="submit">{t('header.submit')}</button>
                            </form>
                        ) : (
                            <p>{t('header.thankYou')}</p>
                        )}
                    </div>
                </div>
            )}
        </header>
    );
};

export default Header;
