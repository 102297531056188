import './HeaderMobile.scss';
import closeButton from '../../assets/images/icons/close.svg';
import { Link } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const HeaderMobile = ({ mobileMenu, setMobileMenu, language, setLanguage }) => {
    const { i18n, t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false); // For booking modal
    const [isSubmitted, setIsSubmitted] = useState(false); // For submission state
    const [formData, setFormData] = useState({
        fullName: '',
        phone: '',
        comment: ''
    });

    const languageChanger = (language) => {
        if (language === 'uz') {
            i18n.changeLanguage('uz');
            setLanguage('uz');
        } else if (language === 'ru') {
            i18n.changeLanguage('ru');
            setLanguage('ru');
        } else if (language === 'eng') {
            i18n.changeLanguage('eng');
            setLanguage('eng');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true); // Mark the form as submitted
        setTimeout(() => {
            setIsModalOpen(false); // Close the modal after 2 seconds
            setIsSubmitted(false); // Reset the submission state
            setFormData({ fullName: '', phone: '', comment: '' }); // Clear form data
        }, 2000);
    };

    const handleBookClick = () => {
        setIsModalOpen(true); // Open the modal
        setMobileMenu(false); // Close the mobile menu
    };

    return (
        <>
            <div
                className={`shadow ${mobileMenu ? 'active' : ''}`}
                onClick={() => setMobileMenu(false)} // Close menu when clicking outside
            />
            <header className={`header-mobile ${mobileMenu ? 'active' : 'hidden'}`}>
                <button
                    className="close-button"
                    onClick={() => setMobileMenu(!mobileMenu)}
                >
                    <img src={closeButton} alt="Close Menu" />
                </button>
                <ul className="menu-items">
                    <li onClick={() => setMobileMenu(false)}>
                        <Link to="/">
                            {t("header.home")}
                        </Link>
                    </li>
                    <li onClick={() => setMobileMenu(false)}>
                        <Link to="/about">
                            {t("header.about")}
                        </Link>
                    </li>
                    <li onClick={() => setMobileMenu(false)}>
                        <Link to="/blog">
                            {t("header.blog")}
                        </Link>
                    </li>
                    <li>
                        <button className="book-btn" onClick={handleBookClick}>
                            {t("header.book")}
                        </button>
                    </li>
                    <li>
                        <div id="language-switcher" className="button-group">
                            <button onClick={() => languageChanger('eng')} id="btn-en">English</button>
                            <button onClick={() => languageChanger('uz')} id="btn-uz">O'zbek</button>
                            <button onClick={() => languageChanger('ru')} id="btn-ru">Русский</button>
                        </div>
                    </li>
                </ul>
            </header>

            {/* Booking Modal */}
            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <button className="close-button" onClick={() => setIsModalOpen(false)}>
                            &times;
                        </button>
                        {!isSubmitted ? (
                            <form onSubmit={handleSubmit}>
                                <h2>{t("header.bookNow")}</h2>
                                <label>
                                    {t("header.fullName")}
                                    <input
                                        type="text"
                                        name="fullName"
                                        value={formData.fullName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </label>
                                <label>
                                    {t("header.phone")}
                                    <input
                                        type="tel"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </label>
                                <label>
                                    {t("header.comment")}
                                    <textarea
                                        name="comment"
                                        value={formData.comment}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </label>
                                <button type="submit">{t("header.submit")}</button>
                            </form>
                        ) : (
                            <p>{t("header.thankYou")}</p>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default HeaderMobile;
