import './BlogPage.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick'
import carouselImg from '../../assets/images/carousel.jpg'
import carouselImg1 from '../../assets/images/carousel-1.jpg'
import carouselImg2 from '../../assets/images/carousel-2.jpg'
import BlogCarousel from "../../components/BlogCarousel";
import BlogCard from "../../components/BlogCard";
import Footer from "../../containers/Footer";
import cardImg1 from "../../assets/images/turk-1.jpg";
import cardImg2 from "../../assets/images/dubai-1.jpg";
import cardImg3 from "../../assets/images/japan-1.jpg";
import cardImg4 from "../../assets/images/europe-1.jpg";
import cardImg5 from "../../assets/images/british-1.jpg";
import cardImg6 from "../../assets/images/beorgia-1.jpg";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

const BlogPage = () => {

    const {t} = useTranslation()

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    };

    const carousel = [
        {
            imgLink: carouselImg
        },
        {
            imgLink: carouselImg1
        },
        {
            imgLink: carouselImg2
        }
    ]
    const constructionInfo = [

        {
            id: 1,
            imgLink: cardImg1,
            title: t("blogPage.constructionCard.0.title"),
            overview: t("blogPage.constructionCard.0.overview"),
            price: "7.500.000 uzs"
        },
        {
            id: 2,
            imgLink: cardImg2,
            title: t("blogPage.constructionCard.1.title"),
            overview: t("blogPage.constructionCard.1.overview"),
            price: "7.000.000 uzs"
        },
        {
            id: 3,
            imgLink: cardImg3,
            title: t("blogPage.constructionCard.2.title"),
            overview: t("blogPage.constructionCard.2.overview"),
            price: "15.000.000 uzs"
        },
        {
            id: 4,
            imgLink: cardImg4,
            title: t("blogPage.constructionCard.3.title"),
            overview: t("blogPage.constructionCard.3.overview"),
            price: "15.500.000 uzs"
        },
        {
            id: 5,
            imgLink: cardImg5,
            title: t("blogPage.constructionCard.4.title"),
            overview: t("blogPage.constructionCard.4.overview"),
            price: "10.000.000 uzs"
        },
        {
            id: 6,
            imgLink: cardImg6,
            title: t("blogPage.constructionCard.5.title"),
            overview: t("blogPage.constructionCard.5.overview"),
            price: "7.000.000 uzs"
        }
    ]



    return (
        <>
            <Helmet>
                <title>
                    Blog Page
                </title>
            </Helmet>
            <div className='blog-carousel'>
                <Slider {...settings}>
                    {
                        carousel.map(item => (
                            <BlogCarousel
                                imgLink={item.imgLink}
                            />
                        ))
                    }
                </Slider>
            </div>
            <div className="construction-cards">
                <div className="container">
                    {
                        constructionInfo.map(item =>(
                            <BlogCard
                                id={item.id}
                                imgLink={item.imgLink}
                                title={item.title}
                                overview={item.overview}
                                price={item.price}
                            />
                        ))
                    }
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default BlogPage
